import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-legipedia-container"
  }, [_c("section", {
    staticClass: "form-wrapper"
  }, [_c("a-form-model", {
    ref: "form",
    attrs: {
      model: _vm.formState,
      rules: _vm.rules
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "标题",
      prop: "name"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "262px"
    },
    attrs: {
      size: "large"
    },
    model: {
      value: _vm.formState.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "name", $$v);
      },
      expression: "formState.name"
    }
  })], 1), _c("a-form-model-item", {
    staticClass: "uploader-wrapper",
    attrs: {
      label: "封面",
      prop: "coverurl"
    }
  }, [_c("a-upload", {
    attrs: {
      "list-type": "picture-card",
      accept: _vm.accept1,
      "show-upload-list": false,
      action: _vm.actionUrl1,
      "before-upload": _vm.beforeUpload.bind(this, "coverurl")
    },
    on: {
      change: function change($event) {
        return _vm.handleChange($event, "coverurl");
      }
    }
  }, [_vm.formState.coverurl ? _c("div", {
    staticClass: "uploader-wrapper"
  }, [_c("div", {
    staticClass: "close-btn",
    on: {
      click: function click($event) {
        return _vm.handleDelUrl($event);
      }
    }
  }, [_vm._v("X")]), _c("img", {
    staticClass: "uploader-img",
    attrs: {
      src: _vm.formState.coverurl
    }
  })]) : _c("div", [_c("a-icon", {
    attrs: {
      type: _vm.loading ? "loading" : "plus"
    }
  }), _c("div", {
    staticClass: "ant-upload-text"
  }, [_vm._v("上传封面")])], 1)]), _c("span", [_vm._v("注：大小不能超过10MB")])], 1), _c("a-form-model-item", {
    attrs: {
      label: "内容",
      prop: "type"
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.formState.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "type", $$v);
      },
      expression: "formState.type"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("图文")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("视频")])], 1)], 1), _c("a-form-model-item", {
    staticClass: "editor-wrapper",
    attrs: {
      prop: "content"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formState.type == 1 || _vm.formState.type == "",
      expression: "formState.type == 1 || formState.type == ''"
    }]
  }, [_c("quill-editor", {
    ref: "quillEditor",
    attrs: {
      editorOption: _vm.editorOption
    },
    on: {
      change: _vm.onEditorChange
    },
    model: {
      value: _vm.quillEditorVal,
      callback: function callback($$v) {
        _vm.quillEditorVal = $$v;
      },
      expression: "quillEditorVal"
    }
  }), _c("form", {
    attrs: {
      action: "",
      method: "post",
      enctype: "multipart/form-data",
      id: "uploadFormMulti"
    }
  }, [_c("input", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: _vm.uniqueId,
      type: "file",
      name: "file",
      multiple: "",
      accept: _vm.accept3
    },
    on: {
      change: function change($event) {
        return _vm.handleUploadImg("uploadFormMulti");
      }
    }
  })])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formState.type == 2,
      expression: "formState.type == 2"
    }]
  }, [_c("a-upload", {
    attrs: {
      "list-type": "picture-card",
      "show-upload-list": false,
      action: _vm.actionUrl2,
      accept: _vm.accept2,
      "before-upload": _vm.beforeUpload.bind(this, "content")
    },
    on: {
      change: function change($event) {
        return _vm.handleChange($event, "content");
      }
    }
  }, [_vm.formState.content ? _c("div", {
    staticClass: "uploader-wrapper"
  }, [_c("div", {
    staticClass: "close-btn",
    on: {
      click: function click($event) {
        return _vm.handleDelUrl2($event);
      }
    }
  }, [_vm._v("X")]), _c("video", {
    staticClass: "uploader-video",
    attrs: {
      src: _vm.videoLink
    }
  })]) : _c("div", [_c("a-icon", {
    attrs: {
      type: _vm.loading2 ? "loading" : "plus"
    }
  }), _c("div", {
    staticClass: "ant-upload-text"
  }, [_vm._v("上传视频")])], 1)]), _c("span", [_vm._v("注：大小不能超过200MB")])], 1)]), _c("a-form-model-item", [_c("div", {
    staticClass: "botton-group"
  }, [_c("a-button", {
    staticStyle: {
      border: "0",
      background: "#f5f5f5"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.loading3
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("确定")])], 1)])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };